import imgT1 from "../assets/image/png/HERUPS.png";
import imgT2 from "../assets/image/png/HumaneOS.png";
import imgT3 from "../assets/image/png/australia-infographic.png";
import imgT4 from "../assets/image/jpg/carbon-emissions.jpeg";
import imgT5 from "../assets/image/png/cos-logo.png";
import imgT6 from "../assets/image/png/grub-run.png";
import imgT7 from "../assets/image/png/watch-it.png";
import imgT8 from "../assets/image/png/spd.png";
import imgT9 from "../assets/image/png/easy-talk.png";

export const gridWorks2 = [
  {
    brand: `Informatics Capstone`,
    slug: `user design`,
    title: `Higher Education Resources for Underprivileged Students`,
    categories: [`user-design`],
    thumbnail: imgT1,
  link : "/herups"
  },
  {
    brand: `HumaneOS`,
    slug: `user-design`,
    title: `HumaneOS`,
    categories: [`user-design`],
    thumbnail: imgT2,
  link : "/humaneos"
  },
  {
    brand: `Australia Bushfires Infographic`,
    slug: `user-design`,
    title: `Australia Bushfires Infographic`,
    categories: [`user-design`],
    thumbnail: "https://res.cloudinary.com/ryandaaang/image/upload/v1616856726/australia-infographic.png",
  link : "/australia-bushfires-infographic"
  },
  {
    brand: `Carbon Emissions`,
    slug: `development`,
    title: `Carbon Emissions`,
    categories: [`development`],
    thumbnail: imgT4,
  link : "/carbon-emissions"
  },
  {
    brand: `Legistar Redesign`,
    slug: `academic-papers`,
    title: `Legistar Redesign`,
    categories: [`academic-papers`],
    thumbnail: imgT5,
  link : "/legistar-redesign"
  },
  {
    brand: `Grub Run`,
    slug: `development`,
    title: `Grub Run`,
    categories: [`development`],
    thumbnail: imgT6,
  link : "grub-run"
  },
  {
    brand: `Watch It`,
    slug: `user-design`,
    title: `Watch It`,
    categories: [`user-design`],
    thumbnail: imgT7,
  link : "watch-it"
  },
  {
    brand: `Seattle Crime Rate`,
    slug: `development`,
    title: `Seattle Crime Rate`,
    categories: [`development`],
    thumbnail: imgT8,
  link : "seattle-crime-rate"
  },
  {
    brand: `Easy Talk`,
    slug: `user-design`,
    title: `Easy Talk`,
    categories: [`user-design`],
    thumbnail: imgT9,
  link : "easy-talk"
  },
];